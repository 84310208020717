import * as Sentry from "@sentry/nuxt";

Sentry.init({
  // If set up, you can use your runtime config here
  dsn:  useRuntimeConfig().public.sentry.environment === "production" ? useRuntimeConfig().public.sentry.dsn : "",
  environment: useRuntimeConfig().public.sentry.environment,
  integrations: [Sentry.browserTracingIntegration()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
